import { OptionType } from '../stockscreen/component/model'
import { Button, Form } from 'antd'
import { useState } from 'react'
import CSwitch from '../../../components/Common/CSwitch/CSwitch'
import { OriginType } from './Blocage'

type LineProps = {
  name?: string
  content?: any
  required?: boolean
  deActiveWhenNull?: boolean
}

export const BlocageLine = ({
  header,
  lines,
  onFinishForm,
  index,
  defaultValues,
  listSsccOptions,
}: {
  header: Array<{ title: string; width: string }>
  lines: LineProps[]
  onFinishForm: (val: any) => void
  index: number
  defaultValues: any
  listSsccOptions?: OptionType[]
}) => {
  const [form] = Form.useForm()
  const [isEditing, setIsEditing] = useState(false)
  const [isActive, setIsActive] = useState(defaultValues.isActive || false)

  function changeStatus(val: boolean) {
    setIsActive(val)
    const allOptionValue = listSsccOptions?.map((item) => item.value)
    form.setFieldsValue(
      !val
        ? {
            motif: '',
            origine: OriginType.WAREHOUSE,
            stock_id_block: [],
          }
        : {
            stock_id_block: allOptionValue,
          }
    )
  }

  function submit(val: any) {
    onFinishForm({
      ...val,
      isActive,
    })
    setIsEditing(false)
  }

  function resetForm() {
    onFinishForm({
      ...form.getFieldsValue(),
      isActive: false,
    })
    setIsEditing(false)
  }

  function changeData(dataChanged: Array<any>) {
    const fieldChange = dataChanged[0]
    const line = lines.find((item) => item.name === fieldChange.name[0])
    if (line?.deActiveWhenNull && !fieldChange.value?.length) {
      setIsActive(false)
    }
  }

  return (
    <Form
      onFieldsChange={changeData}
      onFinish={submit}
      form={form}
      disabled={!isEditing || !isActive}
      initialValues={defaultValues}
    >
      <div className="flex flex-row w-full h-12 rounded-t-2xl">
        {lines.map((item, index) => (
          <div
            key={index}
            className={`h-12 bg-[#FEFEFE] flex bd-right-header-table border-children-cell-table items-center justify-center`}
            style={{ width: header[index]?.width }}
          >
            <div className="px-2 w-full flex items-center justify-center">
              {item.name ? (
                <Form.Item
                  className="h-[25px] w-full !m-0"
                  name={item.name}
                  rules={[
                    {
                      required: isActive ? Boolean(item.required) : false,
                      message: '',
                    },
                  ]}
                >
                  {item.content || '-'}
                </Form.Item>
              ) : (
                <p className="text-center">{item.content || '-'}</p>
              )}
            </div>
          </div>
        ))}
        <div
          className={`h-12 bg-[#FEFEFE] flex border-children-cell-table bd-right-header-table items-center justify-center`}
          style={{ width: '10%' }}
        >
          <div className="flex justify-center align-middle items-center gap-x-2">
            <CSwitch
              disabled={!isEditing}
              onChange={changeStatus}
              checked={isActive}
            />
          </div>
        </div>
        <div
          className={`h-12 bg-[#FEFEFE] flex border-children-cell-table items-center justify-center`}
          style={{ width: '10%' }}
        >
          <div className="flex justify-center align-middle items-center gap-x-2">
            <>
              {isEditing ? (
                <Button
                  onClick={() => (isActive ? form.submit() : resetForm())}
                  disabled={false}
                  className="icon-valid border-none"
                  data-previous-id={`blocageblocage-${index}`}
                  data-next-id={`blocageicon-delete-${index}`}
                  id={`blocageicon-valid-${index}`}
                  data-testid={`blocageicon-valid-${index}`}
                  tabIndex={0}
                />
              ) : (
                <i
                  className="icon-edit"
                  id={`blocageicon-edit-${index}`}
                  data-previous-id={`blocageblocage-${index}`}
                  data-next-id={`blocageicon-delete-${index}`}
                  tabIndex={0}
                  onClick={() => setIsEditing(true)}
                  data-testid={`blocageicon-edit-${index}`}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </Form>
  )
}
