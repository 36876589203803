import Define from '../constants/define'
import { ConditionnementDetailResponse, SSCCListResponse } from '../models'
import baseAxios from './clientAPI'

const conditionnementApi = {
  getSsccInfoInStock(sscc: string): Promise<ConditionnementDetailResponse> {
    const url = 'warehouse/stock/sscc-info'
    const params = {
      client_code_nom:
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
      company_code_nom:
        localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
      warehouse_code_nom:
        localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
      sscc,
    }
    return baseAxios.get(url, { params })
  },

  getListStock(ref: string, lot: string): Promise<SSCCListResponse> {
    const url = 'warehouse/stock/get-list-sscc'
    const params = {
      client_code_nom:
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
      company_code_nom:
        localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
      warehouse_code_nom:
        localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
      reference_id: ref,
      lot,
    }
    return baseAxios.get(url, { params })
  },

  updateSsccBlock(data: {
    motif: string
    origine: string
    sscc_block_status: boolean
    stock_id: string
  }): Promise<any> {
    const url = 'warehouse/stock/sscc-block'
    return baseAxios.patch(url, data)
  },

  updateMultipleSsccBlock(data: any): Promise<any> {
    const url = 'warehouse/stock/sscc-block-many'
    return baseAxios.patch(url, {
      ...data,
      client_code_nom:
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
      company_code_nom:
        localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
      warehouse_code_nom:
        localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
    })
  },
}

export default conditionnementApi
