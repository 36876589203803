import SectionLayout from '../stockscreen/component/SectionLayout'
import { t } from 'i18next'
import { Input, Select } from 'antd'
import './blocage.scss'
import { ConditionnementDetail } from '../../../models'
import conditionnementApi from '../../../http/conditionnementApi'
import moment from 'moment'
import { BlocageLine } from './BlocageLine'

const inputClass = 'h-[25px] w-full !m-0'

export enum OriginType {
  FACTORY = 'Factory',
  WAREHOUSE = 'Warehouse',
}

const SelectOrigin = (
  <Select className={`${inputClass} custom-select`}>
    <Select.Option value={OriginType.FACTORY}>{t('factory')}</Select.Option>
    <Select.Option value={OriginType.WAREHOUSE}>{t('warehouse')}</Select.Option>
  </Select>
)

const Blocage = ({
  listSscc,
  cdn,
  refetchData,
  setLoading,
}: {
  listSscc: any[]
  cdn?: ConditionnementDetail
  refetchData: () => void
  setLoading: (val: boolean) => void
}) => {
  const condition = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )
  const header = [
    { title: t('name'), width: '10%' },
    { title: t('reason'), width: '30%', required: true },
    { title: t('affectedPallet'), width: '20%' },
    { title: t('origin'), width: '10%' },
    { title: t('date'), width: '10%' },
    { title: t('activation'), width: '10%' },
    { title: t('actions'), width: '10%' },
  ]

  const ssccLine = [
    {
      content: (
        <p className="text-[#505050] text-[14px]">{t('blocking')} SSCC</p>
      ),
    },
    {
      content: (
        <Input
          className={`${inputClass} text-center`}
          placeholder="-"
          maxLength={100}
        />
      ),
      name: 'motif',
      required: true,
    },
    {},
    {
      content: SelectOrigin,
      name: 'origine',
    },
    {
      content:
        cdn && !cdn?.sscc_block && cdn.sscc_unlock_at
          ? moment(Number(cdn.sscc_unlock_at) * 1000).format(t('time-format'))
          : '',
    },
  ]

  const listSsccOptions =
    listSscc?.map((item) => {
      return { value: item.stock_id, label: item.sscc }
    }) || []

  const lotLine = [
    {
      content: (
        <p className="text-[#505050] text-[14px]">{t('blocking')} Lot</p>
      ),
    },
    {
      content: (
        <Input
          className={`${inputClass} text-center`}
          placeholder="-"
          maxLength={100}
        />
      ),
      name: 'motif',
      required: true,
    },
    {
      content: (
        <Select
          mode="multiple"
          className={`${inputClass} custom-select-multiple`}
          options={listSsccOptions}
        />
      ),
      name: 'stock_id_block',
      deActiveWhenNull: true,
    },
    {
      content: SelectOrigin,
      name: 'origine',
    },
    {
      content:
        cdn &&
        !cdn?.lot_reference?.lot_block &&
        cdn?.lot_reference?.lot_unlock_at
          ? moment(Number(cdn?.lot_reference?.lot_unlock_at) * 1000).format(
              t('time-format')
            )
          : '',
    },
  ]

  function updateBlocageSSCC(val: any) {
    setLoading(true)
    conditionnementApi
      .updateSsccBlock({
        motif: val.motif || '',
        origine: val.origine,
        stock_id: condition.stock_id,
        sscc_block_status: val.isActive,
      })
      .then(() => {
        refetchData()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  function updateBlocageLot(val: any) {
    setLoading(true)
    let isActive = val.isActive
    if (val.stock_id_block?.length === 0) {
      // when there is no option selected, it means lot is not blocked
      isActive = false
    }
    if (val.stock_id_block?.length === listSsccOptions?.length) {
      // when all option selected, it means lot is blocked
      isActive = true
    }
    conditionnementApi
      .updateMultipleSsccBlock({
        motif: val.motif || '',
        origine: val.origine,
        stock_id_block: val.stock_id_block || [],
        lot_block: isActive,
        lot: condition.lot_stock,
        reference_id: condition.ref_id_stock,
        // option that is not blocked will be add to unblock list
        stock_id_unblock: listSsccOptions
          .filter((item) => !val.stock_id_block?.includes(item.value))
          .map((item) => item.value),
      })
      .then(() => {
        refetchData()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  return (
    <SectionLayout title={t('blocking')}>
      <div className="rounded-t-2xl w-full">
        <div className="flex flex-row w-full h-12 rounded-t-2xl">
          {header.map((item, index) => (
            <div
              key={index}
              className={`h-12 bg-[#EFEFEF] flex  bd-bot-header-table bd-right-header-table border-children-cell-table last:border-r-0 items-center justify-center font-semibold text-[16px]`}
              style={{ width: item.width }}
            >
              <p className={item.required ? 'txt-required' : ''}>
                {item.title}
              </p>
            </div>
          ))}
        </div>
        <BlocageLine
          header={header}
          lines={ssccLine}
          onFinishForm={updateBlocageSSCC}
          index={1}
          defaultValues={
            cdn?.sscc_block
              ? {
                  motif: cdn?.motif || '',
                  origine: cdn?.origine || OriginType.WAREHOUSE,
                  isActive: true,
                }
              : { origine: OriginType.WAREHOUSE }
          }
        />
        {condition.lot_stock ? (
          <BlocageLine
            header={header}
            lines={lotLine}
            onFinishForm={updateBlocageLot}
            index={2}
            defaultValues={
              cdn?.lot_reference?.lot_block
                ? {
                    motif: cdn?.lot_reference?.motif || '',
                    origine:
                      cdn?.lot_reference?.origine || OriginType.WAREHOUSE,
                    isActive: true,
                    stock_id_block: listSscc
                      ?.filter((item) => !item.is_skip_block_lot)
                      ?.map((item) => item.stock_id),
                  }
                : { origine: OriginType.WAREHOUSE }
            }
            listSsccOptions={listSsccOptions}
          />
        ) : null}
      </div>
    </SectionLayout>
  )
}

export default Blocage
